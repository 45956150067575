<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card" @tab-click="changeTab">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" ref="childTab1" />
      </el-tab-pane>
      <!--      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true">-->
      <!--        <childTab3 :isShow="isSho" @draftState="draftState" />-->
      <!--      </el-tab-pane>-->
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab2 ref="childTab2" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import InquiryEditMain from './TabChild/InquiryEditMain.vue';
import InquiryEditAbout from './TabChild/InquiryEditAbout.vue';
import InquiryEditAppr from './TabChild/InquiryEditAppr.vue';
export default {
  name: 'InquiryEdit',
  components: {
    childTab1: InquiryEditMain,
    childTab2: InquiryEditAbout,
    childTab3: InquiryEditAppr
  },
  data() {
    return {
      activeName: 'first',
      isSho: true
    };
  },
  created() {},
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    },
    changeTab(val) {
      if (val.index === '2') {
        this.$refs.childTab2.trigger();
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
