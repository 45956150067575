<template>
  <div class="vg_wrapper">
    <EditHeader
      :isShow="isShow"
      :btn="btn"
      :special="true"
      :strForm="{ status: formStatus }"
      @openEdit="isShow = false"
      @closeEdit="closeEdit"
      @submit="submit"
      @refresh="initData"
    >
      <div slot="functionButton" class="vg_ml_8">
        <div v-if="type === 1">
          <el-button :disabled="!isShow" v-if="formStatus === 0" class="vg_mb_8" type="success" size="small" @click="generateDeptInfo"> 提交采购部 </el-button>
          <el-button :disabled="!isShow" class="vg_mb_8" type="warning" size="small" @click="generateOrderInfo"> 生成订单咨询单 </el-button>
        </div>
        <div v-if="type === 2">
          <el-button :disabled="!isShow" v-if="formStatus === 0" class="vg_mb_8" type="success" size="small" @click="generateDeptInfo"> 提交采购部 </el-button>
        </div>
      </div>
      <div slot="otherButton">
        <div class="flexHV vg_mr_8">
          <span>{{ type === 1 ? '价格' : type === 2 ? '订单' : '' }}询价单编号：</span>
          <el-input disabled size="small" :value="inquiry_no" style="width: 120px"></el-input>
        </div>
      </div>
      <div slot="specialStatus">
        <div class="flexHV">
          <span class="vg_tag_label">状态：</span>
          <el-tag :type="helper.getStatusName(formStatus).type">{{
            formStatus === 0 ? '未报价' : formStatus === 1 ? '报价中' : formStatus === 2 ? '已报价' : ''
          }}</el-tag>
        </div>
      </div>
    </EditHeader>
    <div v-if="type === 1">
      <DynamicForm
        v-bind="$attrs"
        :total-width="mainForm.totalWidth"
        :split="mainForm.split"
        :form-list="mainForm.formProperties"
        ref="dynamicForm"
        class="dynamicForm"
        :is-show="isShow"
      />
      <div class="vg_mb_8">
        <h4>商品信息</h4>
        <div v-if="formStatus === 0">
          <el-button :disabled="isShow" class="vg_mb_8" type="primary" size="small" @click="addRow(1)">新增</el-button>
          <el-button :disabled="isShow" class="vg_mb_8" type="danger" size="small" @click="delRow(1)">删除</el-button>
          <el-button :disabled="isShow" class="vg_mb_8" type="primary" size="small" plain @click="dialogVisible1 = true"> 导入样品核价单 </el-button>
          <el-button :disabled="isShow" class="vg_mb_8" type="primary" size="small" plain @click="dialogVisible2 = true"> 导入二部报价单 </el-button>
          <el-button :disabled="isShow" class="vg_mb_8" type="primary" size="small" plain @click="dialogVisible3 = true"> 导入简易报价单 </el-button>
          <el-button :disabled="isShow" class="vg_mb_8" type="primary" size="small" plain @click="dialogVisible4 = true"> 导入工厂打样单 </el-button>
          <el-button :disabled="isShow" class="vg_mb_8" type="success" size="small" plain @click="generateComponents">生成部件</el-button>
          TIPS 生成部件后不可再编辑成品基础信息
        </div>
        <DynamicUTable
          ref="dynamicUTable1"
          :tableData="computedGoodsTableData"
          :columns="goodsTableProperties"
          :need-pagination="false"
          :is-show="isShow"
          :rowStyle="
            ({ row, rowIndex }) => {
              row.index = rowIndex;
              return { height: '80px' };
            }
          "
          :computedData="{ prod_price: calcProdPrice }"
          :row-click="row => (goodsTableCurrentRow = row)"
          @selection-change="val => (goodsSelections = val)"
        >
        </DynamicUTable>
      </div>
      <div>
        <h4>部件信息</h4>
        <div v-if="formStatus === 0">
          <el-button :disabled="isShow" class="vg_mb_8" type="primary" size="small" @click="copyRow">复制</el-button>
          <el-button :disabled="isShow" class="vg_mb_8" type="danger" size="small" @click="delRow(2)">删除</el-button>
        </div>
        <DynamicUTable
          ref="dynamicUTable2"
          :tableData="computedPartsTableData"
          :columns="partsTableProperties"
          :need-pagination="false"
          :is-show="isShow"
          :rowStyle="
            ({ row, rowIndex }) => {
              row.index = rowIndex;
              return { height: '80px' };
            }
          "
          :computed-data="{ estimat_number: estimat_number }"
          :row-click="row => (partsTableCurrentRow = row)"
          @selection-change="val => (partSelections = val)"
        >
          <template v-slot:operation="scope">
            <el-button type="text" v-if="formStatus > 0" @click="jumpPcstByInquiryPart(174, scope.row.price_consult_id)">查看询价进度</el-button>
          </template>
        </DynamicUTable>
      </div>
    </div>

    <div v-if="type === 2">
      <DynamicForm
        v-bind="$attrs"
        :total-width="mainForm.totalWidth"
        :split="mainForm.split"
        :form-list="mainForm.formProperties2"
        ref="dynamicForm"
        class="dynamicForm"
        :is-show="isShow"
      />
      <div class="vg_mb_8">
        <h4>商品信息</h4>
        <div v-if="formStatus === 0">
          <el-button :disabled="isShow" class="vg_mb_8" type="primary" size="small" @click="addRow(1)">新增</el-button>
          <el-button :disabled="isShow" class="vg_mb_8" type="danger" size="small" @click="delRow(1)">删除</el-button>
          <el-button :disabled="isShow" class="vg_mb_8" type="primary" size="small" plain @click="dialogVisible1 = true">导入样品核价单</el-button>
          <el-button :disabled="isShow" class="vg_mb_8" type="primary" size="small" plain @click="dialogVisible2 = true">导入二部报价单</el-button>
          <el-button :disabled="isShow" class="vg_mb_8" type="primary" size="small" plain @click="dialogVisible3 = true">导入简易报价单</el-button>
          <el-button :disabled="isShow" class="vg_mb_8" type="primary" size="small" plain @click="dialogVisible4 = true">导入工厂打样单</el-button>
          <el-button :disabled="isShow" class="vg_mb_8" type="success" size="small" plain @click="generateComponents">生成部件</el-button>
          TIPS 生成部件后不可再编辑成品基础信息
        </div>
        <DynamicUTable
          ref="dynamicUTable1"
          :tableData="computedGoodsTableData"
          :columns="goodsMoreTableProperties"
          :need-pagination="false"
          :is-show="isShow"
          @selection-change="val => (goodsSelections = val)"
          :rowStyle="
            ({ row, rowIndex }) => {
              row.index = rowIndex;
              return { height: '80px' };
            }
          "
          :row-click="row => (goodsTableCurrentRow = row)"
          :computedData="{ prod_price: calcProdPrice }"
        >
          <template v-slot:generateProductInfo="scope">
            <el-button type="text" v-if="formStatus > 0" @click="showGenerateDialog(scope.row, 1)"> 生成成品信息 </el-button>
          </template>
        </DynamicUTable>
      </div>
      <div>
        <h4>部件信息</h4>
        <div v-if="formStatus === 0">
          <el-button :disabled="isShow" class="vg_mb_8" type="primary" size="small" @click="copyRow">复制</el-button>
          <el-button :disabled="isShow" class="vg_mb_8" type="danger" size="small" @click="delRow(2)">删除</el-button>
        </div>
        <DynamicUTable
          ref="dynamicUTable2"
          :tableData="computedPartsTableData"
          :columns="partsMoreTableProperties"
          :need-pagination="false"
          :rowStyle="
            ({ row, rowIndex }) => {
              row.index = rowIndex;
              return { height: '80px' };
            }
          "
          :is-show="isShow"
          :computed-data="{ estimat_number: estimat_number }"
          :row-click="row => (partsTableCurrentRow = row)"
          @selection-change="val => (partSelections = val)"
        >
          <template v-slot:operation="scope">
            <el-button type="text" v-if="formStatus > 0" @click="jumpPcstByInquiryPart(175, scope.row.consult_id)">查看询价进度</el-button>
            <el-button type="text" v-if="formStatus > 0" @click="showGenerateDialog(scope.row, 2)"> 生成成品信息 </el-button>
          </template>
        </DynamicUTable>
      </div>
    </div>
    <div v-if="isItAProcurementDepartment">
      <el-row :gutter="100">
        <el-col :md="16">
          <h4>采购部核价汇总</h4>
          <DynamicUTable
            ref="dynamicUTable3"
            :auto-height="true"
            :tableData="subTableData.filter(x => x.quta_id === goodsTableCurrentRow.quta_id && x.destroy_flag !== 1)"
            :columns="subTableProperties"
            :need-pagination="false"
            :need-check-box="false"
            :show-summary="true"
            :need-all-column-summary="true"
          >
          </DynamicUTable>
        </el-col>
        <el-col :md="8">
          <table border="1px" width="100%" cellpadding="0" cellspacing="0" class="vd_table">
            <tr>
              <th></th>
              <th style="width: 70%">金额</th>
            </tr>
            <tr>
              <td>利润百分比</td>
              <td>{{ computedSubTableData2.profit_percentage }}</td>
            </tr>
            <tr>
              <td>样品利润金额</td>
              <td>{{ computedSubTableData2.profit_money }}</td>
            </tr>
            <tr>
              <td>含税价格</td>
              <td>{{ computedSubTableData2.includtax_money }}</td>
            </tr>
            <tr>
              <td>毛利率</td>
              <td>{{ computedSubTableData2.gross_margin }}</td>
            </tr>
          </table>
        </el-col>
      </el-row>
    </div>
    <div v-if="(isItAProcurementDepartment || stffForm.stff_id === $cookies.get('userInfo').stff_id) && type === 2">
      <el-row :gutter="100">
        <el-col :md="16">
          <h4>工厂核价明细</h4>
          <DynamicUTable
            ref="dynamicUTable4"
            :auto-height="true"
            :columns="subTableProperties"
            :need-all-column-summary="true"
            :need-check-box="false"
            :need-pagination="false"
            :show-summary="true"
            :tableData="subTableData3.filter(x => x.inquiry_part_id === partsTableCurrentRow.inquiry_part_id && x.destroy_flag !== 1)"
          >
          </DynamicUTable>
        </el-col>
      </el-row>
    </div>
    <el-tabs v-if="isItAProcurementDepartment" v-model="activeName" class="vd_eltab vd_buttom">
      <el-tab-pane label="原材料" name="first" :key="'first'">
        <DynamicUTable
          ref="otherTable1"
          :tableData="otherTableData1.filter(x => x.quta_id === goodsTableCurrentRow.quta_id && x.destroy_flag !== 1)"
          :columns="otherTableProperties1"
          :need-pagination="false"
          :need-check-box="false"
          :is-show="isShow"
        />
        <div style="float: right">
          <span>
            合计:{{
              objectArrayReduce(
                otherTableData1.filter(x => x.quta_id === goodsTableCurrentRow.quta_id && x.destroy_flag !== 1),
                'quta_money',
                4,
                1
              )
            }}
          </span>
        </div>
      </el-tab-pane>
      <el-tab-pane label="辅助材料" name="second" :key="'second'">
        <DynamicUTable
          ref="otherTable2"
          :tableData="otherTableData2.filter(x => x.quta_id === goodsTableCurrentRow.quta_id && x.destroy_flag !== 1)"
          :columns="otherTableProperties2"
          :need-pagination="false"
          :need-check-box="false"
          :is-show="isShow"
        />
        <div style="float: right">
          <span>
            合计:{{
              objectArrayReduce(
                otherTableData2.filter(x => x.quta_id === goodsTableCurrentRow.quta_id && x.destroy_flag !== 1),
                'quta_money',
                4,
                1
              )
            }}
          </span>
        </div>
      </el-tab-pane>
      <el-tab-pane label="配件信息" name="fourth" :key="'fourth'">
        <DynamicUTable
          ref="otherTable1"
          :tableData="otherTableData4.filter(x => x.quta_id === goodsTableCurrentRow.quta_id && x.destroy_flag !== 1)"
          :columns="otherTableProperties4"
          :need-pagination="false"
          :need-check-box="false"
          :is-show="isShow"
        />
        <div style="float: right">
          <span>
            合计:{{
              objectArrayReduce(
                otherTableData4.filter(x => x.quta_id === goodsTableCurrentRow.quta_id && x.destroy_flag !== 1),
                'quta_part_price',
                4,
                1
              )
            }}
          </span>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="样品核价单" top="0" @opened="getDialog1Data" :visible.sync="dialogVisible1" width="70%">
      <div class="vg_mb_8">
        <el-button type="primary" size="small" @click="dialog1Confirm">确认选择</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="dialog1Refresh()"> 刷新</el-button>
      </div>
      <search-table
        ref="multiTable1"
        :data="dialog1TableData"
        :tableRowKey="'dialogVisible1'"
        :columns="dialog1Properties"
        v-loading="dialog1Loading"
        :totalPage="totalPage1"
        :page-size="10"
        :need-fixed-height="true"
        @selection-change="val => (dialog1Selection = val)"
        @getTableData="getDialog1Data"
      >
      </search-table>
    </el-dialog>
    <el-dialog title="二部报价单" top="0" @opened="getDialog2Data" :visible.sync="dialogVisible2" width="70%">
      <div class="vg_mb_8">
        <el-button type="primary" size="small" @click="dialog2Confirm">确认选择</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="dialog2Refresh()"> 刷新</el-button>
      </div>
      <search-table
        ref="multiTable2"
        :data="dialog2TableData"
        :tableRowKey="'dialogVisible2'"
        :columns="dialog2Properties"
        v-loading="dialog2Loading"
        :totalPage="totalPage2"
        :need-fixed-height="true"
        @selection-change="val => (dialog2Selection = val)"
        @getTableData="getDialog2Data"
      >
      </search-table>
    </el-dialog>
    <el-dialog title="简易报价单" top="0" @opened="getDialog3Data" :visible.sync="dialogVisible3" width="70%">
      <div class="vg_mb_8">
        <el-button type="primary" size="small" @click="dialog3Confirm">确认选择</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="dialog3Refresh()"> 刷新</el-button>
      </div>
      <search-table
        ref="multiTable3"
        :data="dialog3TableData"
        :tableRowKey="'dialogVisible3'"
        :columns="dialog3Properties"
        v-loading="dialog3Loading"
        :totalPage="totalPage3"
        :need-fixed-height="true"
        @selection-change="val => (dialog3Selection = val)"
        @getTableData="getDialog3Data"
      >
      </search-table>
    </el-dialog>
    <el-dialog title="工厂打样单" top="0" @opened="getDialog4Data" :visible.sync="dialogVisible4" width="70%">
      <div class="vg_mb_8">
        <el-button type="primary" size="small" @click="dialog4Confirm">确认选择</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="dialog4Refresh()"> 刷新</el-button>
      </div>
      <search-table
        ref="multiTable4"
        :data="dialog4TableData"
        :tableRowKey="'dialogVisible4'"
        :columns="dialog4Properties"
        v-loading="dialog4Loading"
        :totalPage="totalPage4"
        :need-fixed-height="true"
        @selection-change="val => (dialog4Selection = val)"
        @getTableData="getDialog4Data"
      >
      </search-table>
    </el-dialog>
    <el-dialog title="已经存在相同我司货号成品信息" top="0" @opened="getDialog5Data" :visible.sync="dialogVisible5" width="70%">
      <div class="vg_mb_8">
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="dialog5Refresh()"> 刷新</el-button>
      </div>
      <search-table
        ref="multiTable5"
        :data="dialog5TableData"
        :tableRowKey="'dialogVisible5'"
        :columns="dialog5Properties"
        v-loading="dialog5Loading"
        :need-fixed-height="true"
        :need-pagination="false"
        @row-dblclick="generateProdInfo"
        @getTableData="getDialog5Data"
      >
      </search-table>
    </el-dialog>
    <el-dialog title="请选择类型" :visible.sync="changeSyncTypeVisible" width="30%">
      <el-radio v-model="addType" label="1">内贸</el-radio>
      <el-radio v-model="addType" label="2">外销</el-radio>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="changeSyncTypeVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmSyncType">确 定</el-button>
      </span>
    </el-dialog>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser ref="userData" :isCookie="false" :stffForm="stffForm"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditHeader from '@/views/component/editHeaderLine.vue';
import inputUser from '@/views/component/inputUser';
import AddHeader from '@/views/component/addHeader.vue';
import DynamicForm from '@/components/DynamicFormV2.vue';
import {
  dialog1Properties,
  dialog2Properties,
  dialog3Properties,
  dialog4Properties,
  dialog5Properties,
  goodsMoreTableProperties,
  goodsTableProperties,
  inquiry2From,
  inquiryFrom,
  otherTableProperties1,
  otherTableProperties2,
  otherTableProperties3,
  otherTableProperties4,
  partsMoreTableProperties,
  partsTableProperties,
  subTableProperties
} from '@/views/SalesManagement/InquiryManage/inquiry';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { inquiryAPI } from '@api/modules/inquiry';
import { cloneDeep } from 'lodash';
import { objectArrayReduce } from '@assets/js/arrayUtils';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { changeStaffForm, getCustomerInfo, getDept, getStff, getUnit } from '@api/public';
import { BigNumber } from 'bignumber.js';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'inquiryEdit',
  components: {
    SearchTable,
    DynamicUTable,
    DynamicForm,
    AddHeader,
    EditHeader,
    inputUser
  },
  data() {
    return {
      addType: '2',
      stffForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(inquiryFrom),
        formProperties2: cloneDeep(inquiry2From)
      },
      isShow: true,
      btn: {},
      activeNames: ['1', '2'],
      goodsTableProperties: cloneDeep(goodsTableProperties),
      goodsMoreTableProperties: cloneDeep(goodsMoreTableProperties),
      goodsTableData: [],
      goodsSelections: [],
      goodsMap: new Map(),
      partsTableProperties: cloneDeep(partsTableProperties),
      partsMoreTableProperties: cloneDeep(partsMoreTableProperties),
      partsTableData: [],
      partSelections: [],
      partMap: new Map(),
      dialog1Properties: cloneDeep(dialog1Properties),
      dialog2Properties: cloneDeep(dialog2Properties),
      dialog3Properties: cloneDeep(dialog3Properties),
      dialog4Properties: cloneDeep(dialog4Properties),
      dialog5Properties: cloneDeep(dialog5Properties),
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      totalPage1: 0,
      totalPage2: 0,
      totalPage3: 0,
      totalPage4: 0,
      totalPage5: 0,
      dialog1Loading: false,
      dialog2Loading: false,
      dialog3Loading: false,
      dialog4Loading: false,
      dialog5Loading: false,
      dialog1TableData: [],
      dialog2TableData: [],
      dialog3TableData: [],
      dialog4TableData: [],
      dialog5TableData: [],
      dialog1Selection: [],
      dialog2Selection: [],
      dialog3Selection: [],
      dialog4Selection: [],
      dialog5Selection: [],
      inquiry_no: '',
      inquiry_id: '',
      inquiry_prod_id: '',
      formStatus: 0,
      inquiry_type: 0,
      changeSyncTypeVisible: false,
      type: 0,
      activeName: 'first',
      subTableData: [],
      subTableData2: [],
      subTableData3: [],
      otherTableData1: [],
      otherTableData2: [],
      otherTableData4: [],
      subTableProperties: subTableProperties,
      otherTableProperties1: otherTableProperties1,
      otherTableProperties2: otherTableProperties2,
      otherTableProperties3: otherTableProperties3,
      otherTableProperties4: otherTableProperties4,
      goodsTableCurrentRow: {},
      partsTableCurrentRow: {},
      isItAProcurementDepartment: false
    };
  },
  computed: {
    BigNumber() {
      return BigNumber;
    },
    computedGoodsTableData() {
      return this.goodsTableData.filter(x => x.destroy_flag !== 1);
    },
    computedPartsTableData() {
      return this.partsTableData.filter(x => {
        if (x.destroy_flag === 1) return false;
        let { prod_no, prod_suffix, prod_cust_no } = this.goodsTableCurrentRow;
        return x.prod_no + x.prod_suffix + x.prod_cust_no === prod_no + prod_suffix + prod_cust_no;
      });
    },
    computedSubTableData2() {
      return (
        this.subTableData2.filter(x => {
          if (x.destroy_flag === 1) return false;
          return x.quta_id === this.goodsTableCurrentRow.quta_id;
        })?.[0] || {}
      );
    },
    calcProdPrice() {
      let num = 0;
      let temp = this.computedPartsTableData.every(item => {
        if (item.prod_price) {
          num += Number(item.prod_price);
          return true;
        }
        return false;
      });
      this.goodsTableCurrentRow.prod_price = temp ? num.toFixed(4) : '';
      return temp ? undefined : '自动计算';
    },
    estimat_number() {
      let { prod_no: currentProdNo, prod_suffix: currentProdSuffix, prod_cust_no: currentProdCustNo, prod_use } = this.partsTableCurrentRow;
      let find =
        this.goodsTableData.find(
          ({ prod_no, prod_suffix, prod_cust_no }) => prod_no + prod_suffix + prod_cust_no === currentProdNo + currentProdSuffix + currentProdCustNo
        ) || {};
      let temp = new BigNumber(prod_use).times(find.estimat_number);
      if (!temp.isFinite()) {
        this.partsTableCurrentRow.estimat_number = 0;
        return '自动计算';
      }
      this.partsTableCurrentRow.estimat_number = temp.toFixed(2);
      return temp.toFixed(2);
    }
  },
  mounted() {
    const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
    if (props.type) {
      this.type = Number(props.type);
    }
    this.initData();
  },
  methods: {
    objectArrayReduce,
    async initData() {
      let deptArr = await getDept('采购一部', 'ids');
      let { dept_id } = this.$cookies.get('userInfo');
      this.isItAProcurementDepartment = deptArr.includes(dept_id) || dept_id === 1;
      this.changeProperties();
      this.getTableDataById();
    },
    getTableDataById() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.inquiry_id = props.form_id;
      inquiryAPI.get_inquiry_by_id({ inquiry_id: props.form_id }).then(({ data }) => {
        this.btn = data.btn;
        this.inquiry_no = data.form.inquiry_no;
        this.formStatus = data.form.order_status;
        this.inquiry_type = data.form.inquiry_type;
        if (this.type === 1)
          this.mainForm.formProperties.forEach(item => {
            item.model = data.form[item.prop];
          });
        if (this.type === 2)
          this.mainForm.formProperties2.forEach(item => {
            item.model = data.form[item.prop];
          });
        this.goodsTableData = data.form.inquiryProdList;
        this.partsTableData = data.form.inquiryPartList;
        this.subTableData = data.form.rqta_pricings_list;
        this.subTableData2 = data.form.rqta_project_list;
        this.subTableData3 = data.form.rqta_pricings_list1;
        this.otherTableData1 = data.form.quta_rawmtrl_list;
        this.otherTableData2 = data.form.rqta_auxmtrl_list;
        this.otherTableData4 = data.form.quta_partsmtrl_list;
        this.goodsTableCurrentRow = this.goodsTableData[0] || {};
        this.partsTableCurrentRow = this.partsTableData[0] || {};
        changeStaffForm(this.stffForm, data.form);
      });
    },
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true;
        this.$emit('isShow', this.isShow);
        this.initData();
        this.$message.info('已撤销编辑!');
      });
    },
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable1 = await this.$refs.dynamicUTable1.fullValidateMsg();
          let dynamicUTable2 = await this.$refs.dynamicUTable2.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable1 && !dynamicUTable2) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable1) tempStr += `商品-${dynamicUTable1}`;
              if (dynamicUTable2) tempStr += `部件-${dynamicUTable2}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      let saveData = this.getSaveData();
      inquiryAPI.edit_inquiry(saveData).then(({ data }) => {
        this.$message.success('保存成功!');
        this.initData();
        this.isShow = true;
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.bills_property = this.type;
      formModel.order_status = this.formStatus;
      formModel.inquiry_no = this.inquiry_no;
      formModel.inquiry_id = this.inquiry_id;
      formModel.inquiry_type = this.inquiry_type;
      formModel.inquiryProdList = this.goodsTableData.filter(x => (x.inquiry_prod_id ? true : !x.inquiry_prod_id && !x.destroy_flag));
      formModel.inquiryPartList = this.partsTableData.filter(x => (x.inquiry_part_id ? true : !x.inquiry_part_id && !x.destroy_flag));
      formModel.rqta_pricings_list = this.subTableData.filter(x => (x.quta_id ? true : !x.quta_id && !x.destroy_flag));
      formModel.rqta_pricings_list1 = this.subTableData3.filter(x => (x.quta_id ? true : !x.quta_id && !x.destroy_flag));
      formModel.rqta_project_list = this.subTableData2.filter(x => (x.quta_id ? true : !x.quta_id && !x.destroy_flag));
      formModel.quta_rawmtrl_list = this.otherTableData1.filter(x => (x.quta_id ? true : !x.quta_id && !x.destroy_flag));
      formModel.rqta_auxmtrl_list = this.otherTableData2.filter(x => (x.quta_id ? true : !x.quta_id && !x.destroy_flag));
      formModel.quta_partsmtrl_list = this.otherTableData4.filter(x => (x.quta_id ? true : !x.quta_id && !x.destroy_flag));
      return formModel;
    },
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('stffForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    addRow(val) {
      let deepData = cloneDeep(this.$refs[`dynamicUTable${val}`].originalObject);
      deepData.isNew = true;
      deepData.isGenerate = true;
      let { stff_id, stff_name } = this.$cookies.get('userInfo');
      deepData.scon_stff_id = stff_id;
      deepData.scon_stff_name = stff_name;
      if (this.type === 2) deepData.prod_unit = '个';
      if (val === 1) {
        this.goodsTableData.push(deepData);
      }
    },
    delRow(val) {
      if (val === 1) {
        this.goodsSelections.forEach(item => {
          this.$set(item, 'destroy_flag', 1);
          this.partsTableData.forEach(x => {
            if (x.prod_no + x.prod_suffix + x.prod_cust_no === item.prod_no + item.prod_suffix + item.prod_cust_no) {
              x.destroy_flag = 1;
            }
          });
          let tables = ['subTableData', 'subTableData3', 'subTableData2', 'otherTableData1', 'otherTableData2', 'otherTableData4'];
          tables.forEach(table => {
            this[table].forEach(x => {
              if (x.quta_id === item.quta_id) x.destroy_flag = 1;
            });
          });
        });
      }
      if (val === 2) {
        this.partSelections.forEach(item => this.$set(item, 'destroy_flag', 1));
      }
    },
    copyRow() {
      let selection = cloneDeep(this.partSelections);
      selection.forEach(item => {
        item.inquiry_part_id = null;
        item._XID = null;
      });
      this.partsTableData = this.partsTableData.concat(selection);
      this.$refs.dynamicUTable2.clearSelection();
    },
    generateComponents() {
      if (this.goodsSelections.length !== 1) return this.$message.warning('请选择一条数据!');
      if (this.type === 1 && !this.goodsSelections[0].prod_no) return this.$message.warning('该商品无我司货号无法生成部件!');
      if (this.type === 2 && (!this.goodsSelections[0].prod_no || !this.goodsSelections[0].prod_cust_no))
        return this.$message.warning('该商品无我司货号或客户货号无法生成部件!');
      this.goodsSelections[0].isNew = false;
      this.goodsSelections[0].isGenerate = false;
      this.goodsSelections[0]._XID = null;
      let part = cloneDeep(this.$refs.dynamicUTable2.originalObject);
      this.partsTableData.push(Object.assign(part, cloneDeep(this.goodsSelections[0])));
      this.$refs.dynamicUTable1.clearSelection();
    },
    async getDialog1Data() {
      await (this.dialog1Loading = true);
      await inquiryAPI.getToDyjRqtaList(this.$refs.multiTable1.searchForm).then(({ data }) => {
        this.dialog1TableData = data.list;
        this.totalPage1 = data.total;
        this.dialog1Loading = false;
      });
    },
    async getDialog2Data() {
      await (this.dialog2Loading = true);
      await inquiryAPI.getQutbSmpls(this.$refs.multiTable2.searchForm).then(({ data }) => {
        this.dialog2TableData = data.list;
        this.totalPage2 = data.total;
        this.dialog2Loading = false;
      });
    },
    async getDialog3Data() {
      await (this.dialog3Loading = true);
      await inquiryAPI.getQutcSmpls(this.$refs.multiTable3.searchForm).then(({ data }) => {
        this.dialog3TableData = data.list;
        this.totalPage3 = data.total;
        this.dialog3Loading = false;
      });
    },
    async getDialog4Data() {
      await (this.dialog4Loading = true);
      await inquiryAPI.getFequSmpls(this.$refs.multiTable4.searchForm).then(({ data }) => {
        this.dialog4TableData = data.list;
        this.totalPage4 = data.total;
        this.dialog4Loading = false;
      });
    },
    async getDialog5Data() {
      let searchForm = this.$refs.multiTable5.searchForm;
      let { prod_no, prod_cust_no, prod_suffix, prod_partno } = this.generateType === 1 ? this.goodsTableCurrentRow : this.partsTableCurrentRow;
      searchForm.prod_no = prod_no;
      searchForm.prod_cust_no = prod_cust_no;
      searchForm.prod_suffix = prod_suffix;
      searchForm.prod_partno = prod_partno;
      searchForm.type = this.generateType;
      await inquiryAPI.getProdList(searchForm).then(({ data }) => {
        this.dialog5Loading = true;
        this.dialog5TableData = data;
        this.dialog5Loading = false;
      });
    },
    dialog1Confirm() {
      if (this.dialog1Selection.length === 0) return this.$message.warning('请至少选择一条数据!');
      let temp = [];
      this.dialog1Selection.forEach(item => {
        let goodObj = cloneDeep(this.$refs.dynamicUTable1.originalObject);
        goodObj.imge_id = item.imge_id;
        goodObj.imge_url = item.imge_url;
        goodObj.prod_no = item.smpl_no;
        goodObj.prod_name = item.smpl_name;
        goodObj.prod_ename = item.smpl_name;
        goodObj.requ_smpl_no = item.requ_no;
        goodObj.scon_stff_name = item.requ_stff_name;
        goodObj.total_price = item.quta_total;
        goodObj.quta_id = item.quta_id;
        goodObj.prod_suffix = item.prod_suffix;
        goodObj.prod_cust_no = item.smpl_cust_no;
        goodObj.isGenerate = true;
        goodObj.data_sources = 1;
        goodObj.docu_pdfurl = item.docu_pdfurl;
        let find = this.goodsTableData.filter(x => x.destroy_flag !== 1).find(x => x.quta_id === item.quta_id);
        if (find) return temp.push('我司货号:' + goodObj.prod_no + '更改号:' + goodObj.prod_suffix);
        this.goodsTableData.push(goodObj);
        this.subTableData = this.subTableData.concat(item.rqta_pricings_list);
        this.subTableData2.push(item.rqtaProjet);
        this.otherTableData1 = this.otherTableData1.concat(item.quta_rawmtrl_list);
        this.otherTableData2 = this.otherTableData2.concat(item.rqta_auxmtrl_list);
        this.otherTableData4 = this.otherTableData4.concat(item.quta_partsmtrl_list);
      });
      if (temp.length > 0) this.$message.warning(temp.toString() + '已被导入，不可重复导入');
      this.$refs.multiTable1.$refs.multiTable.clearSelection();
      this.dialogVisible1 = false;
    },
    dialog2Confirm() {
      if (this.dialog2Selection.length === 0) return this.$message.warning('请至少选择一条数据!');
      let temp = [];
      this.dialog2Selection.forEach(item => {
        let goodObj = cloneDeep(this.$refs.dynamicUTable1.originalObject);
        goodObj.data_sources = 2;
        goodObj.isGenerate = true;
        let find = this.goodsTableData.filter(x => x.destroy_flag !== 1).find(x => x.qutb_id === item.qutb_id);
        if (find) return temp.push('我司货号:' + item.prod_no + '更改号:' + item.prod_suffix);
        this.goodsTableData.push(Object.assign(goodObj, item));
      });
      if (temp.length > 0) this.$message.warning(temp.toString() + '已被导入，不可重复导入');
      this.$refs.multiTable2.$refs.multiTable.clearSelection();
      this.dialogVisible2 = false;
    },
    dialog3Confirm() {
      if (this.dialog3Selection.length === 0) return this.$message.warning('请至少选择一条数据!');
      let temp = [];
      this.dialog3Selection.forEach(item => {
        let goodObj = cloneDeep(this.$refs.dynamicUTable1.originalObject);
        goodObj.data_sources = 3;
        goodObj.isGenerate = true;
        let find = this.goodsTableData.filter(x => x.destroy_flag !== 1).find(x => x.qutc_smpl_id === item.qutc_smpl_id);
        if (find) return temp.push('我司货号:' + item.prod_no + '更改号:' + item.prod_suffix);
        this.goodsTableData.push(Object.assign(goodObj, item));
      });
      if (temp.length > 0) this.$message.warning(temp.toString() + '已被导入，不可重复导入');
      this.$refs.multiTable3.$refs.multiTable.clearSelection();
      this.dialogVisible3 = false;
    },
    dialog4Confirm() {
      if (this.dialog4Selection.length === 0) return this.$message.warning('请至少选择一条数据!');
      let temp = [];
      this.dialog4Selection.forEach(item => {
        let goodObj = cloneDeep(this.$refs.dynamicUTable1.originalObject);
        goodObj.data_sources = 4;
        goodObj.isGenerate = true;
        let find = this.goodsTableData.filter(x => x.destroy_flag !== 1).find(x => x.fequ_smpl_id === item.fequ_smpl_id);
        if (find) return temp.push('我司货号:' + item.prod_no + '更改号:' + item.prod_suffix);
        this.goodsTableData.push(Object.assign(goodObj, item));
      });
      if (temp.length > 0) this.$message.warning(temp.toString() + '已被导入，不可重复导入');
      this.$refs.multiTable4.$refs.multiTable.clearSelection();
      this.dialogVisible4 = false;
    },
    async generateProdInfo(row) {
      await (this.dialogVisible5 = false);
      if (this.generateType === 1) {
        await inquiryAPI
          .addProdList({
            inquiry_part_id: this.inquiry_part_id,
            inquiry_prod_id: this.inquiry_prod_id,
            inquiry_id: this.inquiry_id,
            prod_id: row.prod_id,
            part_id: row.part_id
          })
          .then(({ data }) => {
            this.$router.push({
              name: 'ProdEdit',
              query: {
                key: UrlEncode.encode(
                  JSON.stringify({
                    perm_id: 124,
                    form_id: data.prod_attr === '2' ? data.part_id : data.prod_id,
                    prod_no: data.prod_no,
                    prod_attr: data.prod_attr,
                    prod_ename: data.prod_ename,
                    prod_partno: data.prod_partno,
                    prod_cust_no: data.prod_cust_no
                  })
                )
              },
              params: { syncData: data }
            });
          });
      } else {
        await inquiryAPI
          .addPartList({
            inquiry_part_id: this.inquiry_part_id,
            inquiry_prod_id: this.inquiry_prod_id,
            inquiry_id: this.inquiry_id,
            prod_id: row.prod_id,
            part_id: row.part_id
          })
          .then(({ data }) => {
            this.$router.push({
              name: 'ProdEdit',
              query: {
                key: UrlEncode.encode(
                  JSON.stringify({
                    perm_id: 124,
                    form_id: data.prod_attr === '2' ? data.part_id : data.prod_id,
                    prod_no: data.prod_no,
                    prod_attr: data.prod_attr,
                    prod_ename: data.prod_ename,
                    prod_partno: data.prod_partno,
                    prod_cust_no: data.prod_cust_no
                  })
                )
              },
              params: { syncData: data }
            });
          });
      }
    },

    async showGenerateDialog(row, type) {
      this.generateType = type;
      let searchForm = {};
      let { prod_no, prod_cust_no, prod_suffix, prod_partno } = row;
      searchForm.prod_no = prod_no;
      searchForm.prod_cust_no = prod_cust_no;
      searchForm.prod_suffix = prod_suffix;
      searchForm.prod_partno = prod_partno;
      searchForm.type = this.generateType;
      let data = await inquiryAPI.getProdList(searchForm);
      if (data.data.length === 0) {
        this.inquiry_prod_id = row.inquiry_prod_id;
        this.inquiry_part_id = row.inquiry_part_id;
        this.changeSyncTypeVisible = true;
      } else {
        await (this.dialogVisible5 = true);
        this.inquiry_prod_id = row.inquiry_prod_id;
        this.inquiry_part_id = row.inquiry_part_id;
        await this.getDialog5Data();
      }
    },
    confirmSyncType() {
      if (this.generateType === 1) {
        inquiryAPI.addProdList({ inquiry_prod_id: this.inquiry_prod_id, inquiry_id: this.inquiry_id }).then(async ({ data }) => {
          await (this.changeSyncTypeVisible = false);
          data.prod_sales = Number(this.addType);
          data.prod_category = '毛绒玩具';
          data.prod_part_list.forEach(item => {
            item.supp_abbr = item.supp_id;
            item.supplementInfo = { prod_hscode: '', prod_spec: '', prod_sales: data.prod_sales };
            item.submit = false;
          });
          await this.$router.push({
            name: 'ProdAdd',
            query: { perm_id: 124, addType: this.addType, UUID: crypto.randomUUID() },
            params: { syncData: data }
          });
        });
      } else {
        inquiryAPI.addPartList({ inquiry_part_id: this.inquiry_part_id, inquiry_id: this.inquiry_id }).then(async ({ data }) => {
          await (this.changeSyncTypeVisible = false);
          data.prod_category = '毛绒玩具';
          data.prod_sales = Number(this.addType);
          data.prod_part_list.forEach(item => {
            item.supp_abbr = item.supp_id;
            item.supplementInfo = { prod_hscode: '', prod_spec: '', prod_sales: data.prod_sales };
            item.submit = false;
          });
          await this.$router.push({
            name: 'ProdAdd',
            query: { perm_id: 124, addType: this.addType, UUID: crypto.randomUUID() },
            params: { syncData: data }
          });
        });
      }
    },
    dialog1Refresh() {
      this.$refs.multiTable1.resetFields();
      this.getDialog1Data();
    },
    dialog2Refresh() {
      this.$refs.multiTable2.resetFields();
      this.getDialog2Data();
    },
    dialog3Refresh() {
      this.$refs.multiTable3.resetFields();
      this.getDialog3Data();
    },
    dialog4Refresh() {
      this.$refs.multiTable4.resetFields();
      this.getDialog4Data();
    },
    dialog5Refresh() {
      this.$refs.multiTable5.resetFields();
      this.getDialog5Data();
    },
    generateOrderInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      inquiryAPI.copy_inquiry(this.getSaveData()).then(async ({ data }) => {
        await (this.inquiry_type = data.inquiry_type);
        await this.jump('/inquiry_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: props.perm_id,
              form_id: data.form_id,
              t: Date.now(),
              type: 2
            })
          )
        });
      });
    },
    generateDeptInfo() {
      this.$confirm('是否确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          if (this.type === 1) {
            inquiryAPI.addPriceConsultList({ inquiry_id: this.inquiry_id }).then(async ({ data }) => {
              await (this.formStatus = data);
              await this.$message.success('提交成功!');
              await this.getTableDataById();
              //await this.jump('/price_consult_list', { perm_id: 174, inquiry_no: this.inquiry_no });
            });
          }
          if (this.type === 2) {
            inquiryAPI.add_consult({ inquiry_id: this.inquiry_id }).then(async ({ data }) => {
              await (this.formStatus = data);
              await this.$message.success('提交成功!');
              await this.getTableDataById();
              //await this.jump('/consult_list', { perm_id: 175, inquiry_no: this.inquiry_no });
            });
          }
        })
        .catch(err => console.error(err));
    },
    async changeProperties() {
      let businessDept = [...(await getDept('业务')), ...(await getDept('内贸')), ...(await getDept('电商'))];
      let businessStff = [...(await getStff('业务')), ...(await getStff('内贸')), ...(await getStff('电商'))];
      let customerAbbr = await getCustomerInfo({ status: 2, type: 'abbr' });
      // 价格咨询单
      if (this.type === 1) {
        this.mainForm.formProperties.find(({ label }) => label === '客户简称').options = customerAbbr;
        this.mainForm.formProperties.find(({ label }) => label === '销售部门').options = businessDept;

        this.goodsTableProperties[1].subItem.change = () => this.nonRepeatable(1); // 我司货号
        this.goodsTableProperties[2].subItem.change = () => this.nonRepeatable(2); // 更改号
        this.goodsTableProperties[3].subItem.change = () => this.nonRepeatable(3); // 客户货号

        let goodsSconStff = this.goodsTableProperties.find(({ prop }) => prop === 'scon_stff_id');
        goodsSconStff.subItem.options = businessStff;
        goodsSconStff.subItem.change = (val, row, options) => {
          row.scon_stff_name = options.find(({ value }) => value === val).label;
          this.partsTableData
            .filter(x => x.destroy_flag !== 1)
            .forEach(item => {
              if (item.prod_no + item.prod_suffix + item.prod_cust_no === row.prod_no + row.prod_suffix + row.prod_cust_no) {
                item.scon_stff_id = val;
                this.$set(item, 'scon_stff_name', row.scon_stff_name);
              }
            });
        };

        let deliveryTime = this.goodsTableProperties.find(({ prop }) => prop === 'delivery_time');
        deliveryTime.subItem.change = (val, row, options) => {
          this.partsTableData
            .filter(x => x.destroy_flag !== 1)
            .forEach(item => {
              if (item.prod_no + item.prod_suffix + item.prod_cust_no === row.prod_no + row.prod_suffix + row.prod_cust_no) {
                item.delivery_time = val;
              }
            });
        };
        let purch_dept_id = this.goodsTableProperties.find(({ prop }) => prop === 'purch_dept_id');
        purch_dept_id.subItem.change = (val, row, options) => {
          this.partsTableData
            .filter(x => x.destroy_flag !== 1)
            .forEach(item => {
              if (item.prod_no + item.prod_suffix + item.prod_cust_no === row.prod_no + row.prod_suffix + row.prod_cust_no) {
                item.purch_dept_id = val;
              }
            });
        };
      }
      // 订单咨询单
      if (this.type === 2) {
        this.mainForm.formProperties2[this.mainForm.formProperties.findIndex(({ label }) => label === '客户简称')].options = customerAbbr;

        this.goodsMoreTableProperties[1].subItem.change = () => this.nonRepeatable(1); // 我司货号
        this.goodsMoreTableProperties[2].subItem.change = () => this.nonRepeatable(2); // 更改号
        this.goodsMoreTableProperties[3].subItem.change = () => this.nonRepeatable(3); // 客户货号

        let unitArr = await getUnit('select', 'param1');
        this.goodsMoreTableProperties.find(({ label }) => label === '单位').subItem.options = unitArr;
        this.partsMoreTableProperties.find(({ label }) => label === '单位').subItem.options = unitArr;
        this.mainForm.formProperties2.find(({ label }) => label === '销售部门').options = businessDept;

        let goodsMoreSconStff = this.goodsMoreTableProperties.find(({ prop }) => prop === 'scon_stff_id');
        goodsMoreSconStff.subItem.options = businessStff;
        goodsMoreSconStff.subItem.change = (val, row, options) => {
          row.scon_stff_name = options.find(({ value }) => value === val).label;
          this.partsTableData
            .filter(x => x.destroy_flag !== 1)
            .forEach(item => {
              if (item.prod_no + item.prod_suffix + item.prod_cust_no === row.prod_no + row.prod_suffix + row.prod_cust_no) {
                item.scon_stff_id = val;
                this.$set(item, 'scon_stff_name', row.scon_stff_name);
              }
            });
        };
        let deliveryTime = this.goodsMoreTableProperties.find(({ prop }) => prop === 'delivery_time');
        deliveryTime.subItem.change = (val, row, options) => {
          this.partsTableData
            .filter(x => x.destroy_flag !== 1)
            .forEach(item => {
              if (item.prod_no + item.prod_suffix + item.prod_cust_no === row.prod_no + row.prod_suffix + row.prod_cust_no) {
                item.delivery_time = val;
              }
            });
        };
        let purch_dept_id = this.goodsTableProperties.find(({ prop }) => prop === 'purch_dept_id');
        purch_dept_id.subItem.change = (val, row, options) => {
          this.partsTableData
            .filter(x => x.destroy_flag !== 1)
            .forEach(item => {
              if (item.prod_no + item.prod_suffix + item.prod_cust_no === row.prod_no + row.prod_suffix + row.prod_cust_no) {
                item.purch_dept_id = val;
              }
            });
        };
      }
    },
    jumpPcstByInquiryPart(permId, formId) {
      this.jump(permId === 174 ? '/price_consult_edit' : 'consult_edit', {
        key: UrlEncode.encode(JSON.stringify({ perm_id: permId, form_id: formId }))
      });
    },
    nonRepeatable(cIndex) {
      let { prod_no, prod_suffix, prod_cust_no } = this.goodsTableCurrentRow;
      let filter = this.goodsTableData
        .filter(x => x.destroy_flag !== 1)
        .filter(x => x.prod_no + x.prod_suffix + x.prod_cust_no === prod_no + prod_suffix + prod_cust_no);
      if (filter.length > 1) {
        this.$message.warning('该我司货号,更改号,客户货号已存在!');
        switch (cIndex) {
          case 1:
            this.goodsTableCurrentRow.prod_no = '';
            break;
          case 2:
            this.goodsTableCurrentRow.prod_suffix = '';
            break;
          case 3:
            this.goodsTableCurrentRow.prod_cust_no = '';
            break;
        }
      }
    }
  }
};
</script>

<style scoped>
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
::v-deep .elx-table .elx-body--column.col--ellipsis > .elx-cell {
  max-height: 80px;
}
</style>
